const ActionTypes = [
  {
    value: 0,
    name: 'RoleList',
    displayName: 'ActionTypeList',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'RoleAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'RoleCreate',
    displayName: 'ActionTypeCreate',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'RoleUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'RoleDelete',
    displayName: 'ActionTypeDelete',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'OwnerList',
    displayName: 'ActionTypeList',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      userRole: 0,
      userRoleActionType: 0
    }
  },
  {
    value: 6,
    name: 'OwnerAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 700,
    data: {
      userRole: 0,
      userRoleActionType: 1
    }
  },
  {
    value: 7,
    name: 'OwnerAll',
    displayName: 'ActionTypeAll',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 800,
    data: {
      userRole: 0,
      userRoleActionType: 2
    }
  },
  {
    value: 8,
    name: 'OwnerCreate',
    displayName: 'ActionTypeCreate',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 900,
    data: {
      userRole: 0,
      userRoleActionType: 4
    }
  },
  {
    value: 9,
    name: 'OwnerUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 1000,
    data: {
      userRole: 0,
      userRoleActionType: 5
    }
  },
  {
    value: 10,
    name: 'OwnerDelete',
    displayName: 'ActionTypeDelete',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 1100,
    data: {
      userRole: 0,
      userRoleActionType: 6
    }
  },
  {
    value: 11,
    name: 'AdministratorList',
    displayName: 'ActionTypeList',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1200,
    data: {
      userRole: 1,
      userRoleActionType: 0
    }
  },
  {
    value: 12,
    name: 'AdministratorAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1300,
    data: {
      userRole: 1,
      userRoleActionType: 1
    }
  },
  {
    value: 13,
    name: 'AdministratorAll',
    displayName: 'ActionTypeAll',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1400,
    data: {
      userRole: 1,
      userRoleActionType: 2
    }
  },
  {
    value: 14,
    name: 'AdministratorCreate',
    displayName: 'ActionTypeCreate',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1500,
    data: {
      userRole: 1,
      userRoleActionType: 4
    }
  },
  {
    value: 15,
    name: 'AdministratorUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1600,
    data: {
      userRole: 1,
      userRoleActionType: 5
    }
  },
  {
    value: 16,
    name: 'AdministratorDelete',
    displayName: 'ActionTypeDelete',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1700,
    data: {
      userRole: 1,
      userRoleActionType: 6
    }
  },
  {
    value: 17,
    name: 'ManagerList',
    displayName: 'ActionTypeList',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1800,
    data: {
      userRole: 2,
      userRoleActionType: 0
    }
  },
  {
    value: 18,
    name: 'ManagerAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 1900,
    data: {
      userRole: 2,
      userRoleActionType: 1
    }
  },
  {
    value: 19,
    name: 'ManagerAll',
    displayName: 'ActionTypeAll',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2000,
    data: {
      userRole: 2,
      userRoleActionType: 2
    }
  },
  {
    value: 20,
    name: 'ManagerCreate',
    displayName: 'ActionTypeCreate',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2100,
    data: {
      userRole: 2,
      userRoleActionType: 4
    }
  },
  {
    value: 21,
    name: 'ManagerUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2200,
    data: {
      userRole: 2,
      userRoleActionType: 5
    }
  },
  {
    value: 22,
    name: 'ManagerDelete',
    displayName: 'ActionTypeDelete',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2300,
    data: {
      userRole: 2,
      userRoleActionType: 6
    }
  },
  {
    value: 23,
    name: 'EditorList',
    displayName: 'ActionTypeList',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 2400,
    data: {
      userRole: 3,
      userRoleActionType: 0
    }
  },
  {
    value: 24,
    name: 'EditorAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2500,
    data: {
      userRole: 3,
      userRoleActionType: 1
    }
  },
  {
    value: 25,
    name: 'EditorAll',
    displayName: 'ActionTypeAll',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2600,
    data: {
      userRole: 3,
      userRoleActionType: 2
    }
  },
  {
    value: 26,
    name: 'EditorCreate',
    displayName: 'ActionTypeCreate',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2700,
    data: {
      userRole: 3,
      userRoleActionType: 4
    }
  },
  {
    value: 27,
    name: 'EditorUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2800,
    data: {
      userRole: 3,
      userRoleActionType: 5
    }
  },
  {
    value: 28,
    name: 'EditorDelete',
    displayName: 'ActionTypeDelete',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2900,
    data: {
      userRole: 3,
      userRoleActionType: 6
    }
  },
  {
    value: 29,
    name: 'PSPList',
    displayName: 'ActionTypeList',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 3000,
    data: {
      userRole: 4,
      userRoleActionType: 0
    }
  },
  {
    value: 30,
    name: 'PSPAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3100,
    data: {
      userRole: 4,
      userRoleActionType: 1
    }
  },
  {
    value: 31,
    name: 'PSPAll',
    displayName: 'ActionTypeAll',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3200,
    data: {
      userRole: 4,
      userRoleActionType: 2
    }
  },
  {
    value: 32,
    name: 'PSPCreate',
    displayName: 'ActionTypeCreate',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3300,
    data: {
      userRole: 4,
      userRoleActionType: 4
    }
  },
  {
    value: 33,
    name: 'PSPUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3400,
    data: {
      userRole: 4,
      userRoleActionType: 5
    }
  },
  {
    value: 34,
    name: 'PSPDelete',
    displayName: 'ActionTypeDelete',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3500,
    data: {
      userRole: 4,
      userRoleActionType: 6
    }
  },
  {
    value: 35,
    name: 'DoctorList',
    displayName: 'ActionTypeList',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 3600,
    data: {
      userRole: 5,
      userRoleActionType: 0
    }
  },
  {
    value: 36,
    name: 'DoctorAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 3700,
    data: {
      userRole: 5,
      userRoleActionType: 1
    }
  },
  {
    value: 37,
    name: 'DoctorAll',
    displayName: 'ActionTypeAll',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 3800,
    data: {
      userRole: 5,
      userRoleActionType: 2
    }
  },
  {
    value: 38,
    name: 'DoctorCreate',
    displayName: 'ActionTypeCreate',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 3900,
    data: {
      userRole: 5,
      userRoleActionType: 4
    }
  },
  {
    value: 39,
    name: 'DoctorUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 4000,
    data: {
      userRole: 5,
      userRoleActionType: 5
    }
  },
  {
    value: 40,
    name: 'DoctorDelete',
    displayName: 'ActionTypeDelete',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 4100,
    data: {
      userRole: 5,
      userRoleActionType: 6
    }
  },
  {
    value: 41,
    name: 'NurseList',
    displayName: 'ActionTypeList',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 4200,
    data: {
      userRole: 6,
      userRoleActionType: 0
    }
  },
  {
    value: 42,
    name: 'NurseAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4300,
    data: {
      userRole: 6,
      userRoleActionType: 1
    }
  },
  {
    value: 43,
    name: 'NurseAll',
    displayName: 'ActionTypeAll',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4400,
    data: {
      userRole: 6,
      userRoleActionType: 2
    }
  },
  {
    value: 44,
    name: 'NurseCreate',
    displayName: 'ActionTypeCreate',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4500,
    data: {
      userRole: 6,
      userRoleActionType: 4
    }
  },
  {
    value: 45,
    name: 'NurseUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4600,
    data: {
      userRole: 6,
      userRoleActionType: 5
    }
  },
  {
    value: 46,
    name: 'NurseDelete',
    displayName: 'ActionTypeDelete',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4700,
    data: {
      userRole: 6,
      userRoleActionType: 6
    }
  },
  {
    value: 47,
    name: 'PharmacistList',
    displayName: 'ActionTypeList',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 4800,
    data: {
      userRole: 7,
      userRoleActionType: 0
    }
  },
  {
    value: 48,
    name: 'PharmacistAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 4900,
    data: {
      userRole: 7,
      userRoleActionType: 1
    }
  },
  {
    value: 49,
    name: 'PharmacistAll',
    displayName: 'ActionTypeAll',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5000,
    data: {
      userRole: 7,
      userRoleActionType: 2
    }
  },
  {
    value: 50,
    name: 'PharmacistCreate',
    displayName: 'ActionTypeCreate',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5100,
    data: {
      userRole: 7,
      userRoleActionType: 4
    }
  },
  {
    value: 51,
    name: 'PharmacistUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5200,
    data: {
      userRole: 7,
      userRoleActionType: 5
    }
  },
  {
    value: 52,
    name: 'PharmacistDelete',
    displayName: 'ActionTypeDelete',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5300,
    data: {
      userRole: 7,
      userRoleActionType: 6
    }
  },
  {
    value: 53,
    name: 'WarehouseList',
    displayName: 'ActionTypeList',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 5400,
    data: {
      userRole: 8,
      userRoleActionType: 0
    }
  },
  {
    value: 54,
    name: 'WarehouseAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5500,
    data: {
      userRole: 8,
      userRoleActionType: 1
    }
  },
  {
    value: 55,
    name: 'WarehouseAll',
    displayName: 'ActionTypeAll',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5600,
    data: {
      userRole: 8,
      userRoleActionType: 2
    }
  },
  {
    value: 56,
    name: 'WarehouseCreate',
    displayName: 'ActionTypeCreate',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5700,
    data: {
      userRole: 8,
      userRoleActionType: 4
    }
  },
  {
    value: 57,
    name: 'WarehouseUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5800,
    data: {
      userRole: 8,
      userRoleActionType: 5
    }
  },
  {
    value: 58,
    name: 'WarehouseDelete',
    displayName: 'ActionTypeDelete',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5900,
    data: {
      userRole: 8,
      userRoleActionType: 6
    }
  },
  {
    value: 59,
    name: 'IMSList',
    displayName: 'ActionTypeList',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 6000,
    data: {
      userRole: 9,
      userRoleActionType: 0
    }
  },
  {
    value: 60,
    name: 'IMSAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6100,
    data: {
      userRole: 9,
      userRoleActionType: 1
    }
  },
  {
    value: 61,
    name: 'IMSAll',
    displayName: 'ActionTypeAll',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6200,
    data: {
      userRole: 9,
      userRoleActionType: 2
    }
  },
  {
    value: 62,
    name: 'IMSCreate',
    displayName: 'ActionTypeCreate',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6300,
    data: {
      userRole: 9,
      userRoleActionType: 4
    }
  },
  {
    value: 63,
    name: 'IMSUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6400,
    data: {
      userRole: 9,
      userRoleActionType: 5
    }
  },
  {
    value: 64,
    name: 'IMSDelete',
    displayName: 'ActionTypeDelete',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6500,
    data: {
      userRole: 9,
      userRoleActionType: 6
    }
  },
  {
    value: 65,
    name: 'PatientList',
    displayName: 'ActionTypeList',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 6600,
    data: {
      userRole: 10,
      userRoleActionType: 0
    }
  },
  {
    value: 66,
    name: 'PatientAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 6700,
    data: {
      userRole: 10,
      userRoleActionType: 1
    }
  },
  {
    value: 67,
    name: 'PatientAll',
    displayName: 'ActionTypeAll',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 6800,
    data: {
      userRole: 10,
      userRoleActionType: 2
    }
  },
  {
    value: 68,
    name: 'PatientJourney',
    displayName: 'ActionTypeJourney',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 6900,
    data: {
      userRole: 10,
      userRoleActionType: 3
    }
  },
  {
    value: 69,
    name: 'PatientCreate',
    displayName: 'ActionTypeCreate',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 7000,
    data: {
      userRole: 10,
      userRoleActionType: 4
    }
  },
  {
    value: 70,
    name: 'PatientUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 7100,
    data: {
      userRole: 10,
      userRoleActionType: 5
    }
  },
  {
    value: 71,
    name: 'PatientDelete',
    displayName: 'ActionTypeDelete',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 7200,
    data: {
      userRole: 10,
      userRoleActionType: 6
    }
  },
  {
    value: 72,
    name: 'CityList',
    displayName: 'ActionTypeList',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 7300,
    data: {}
  },
  {
    value: 73,
    name: 'CityAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 7400,
    data: {}
  },
  {
    value: 74,
    name: 'CityCreate',
    displayName: 'ActionTypeCreate',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 7500,
    data: {}
  },
  {
    value: 75,
    name: 'CityUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 7600,
    data: {}
  },
  {
    value: 76,
    name: 'CityDelete',
    displayName: 'ActionTypeDelete',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 7700,
    data: {}
  },
  {
    value: 77,
    name: 'LocalizationList',
    displayName: 'ActionTypeList',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 7800,
    data: {}
  },
  {
    value: 78,
    name: 'LocalizationAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 7900,
    data: {}
  },
  {
    value: 79,
    name: 'LocalizationExcelImportExport',
    displayName: 'ActionTypeImportExport',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 8000,
    data: {}
  },
  {
    value: 80,
    name: 'LocalizationCreate',
    displayName: 'ActionTypeCreate',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 8100,
    data: {}
  },
  {
    value: 81,
    name: 'LocalizationUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 8200,
    data: {}
  },
  {
    value: 82,
    name: 'LocalizationDelete',
    displayName: 'ActionTypeDelete',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 8300,
    data: {}
  },
  {
    value: 83,
    name: 'TemplateList',
    displayName: 'ActionTypeList',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8400,
    data: {}
  },
  {
    value: 84,
    name: 'TemplateCreate',
    displayName: 'ActionTypeCreate',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: 'TemplateList',
    order: 8500,
    data: {}
  },
  {
    value: 85,
    name: 'TemplateUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: 'TemplateList',
    order: 8600,
    data: {}
  },
  {
    value: 86,
    name: 'NoticeList',
    displayName: 'ActionTypeList',
    group: 'NoticeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8700,
    data: {}
  },
  {
    value: 87,
    name: 'NoticeAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'NoticeManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeList',
    order: 8800,
    data: {}
  },
  {
    value: 88,
    name: 'NoticeContactList',
    displayName: 'ActionTypeList',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8900,
    data: {}
  },
  {
    value: 89,
    name: 'NoticeContactAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 9000,
    data: {}
  },
  {
    value: 90,
    name: 'NoticeContactCreate',
    displayName: 'ActionTypeCreate',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 9100,
    data: {}
  },
  {
    value: 91,
    name: 'NoticeContactUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 9200,
    data: {}
  },
  {
    value: 92,
    name: 'NoticeContactDelete',
    displayName: 'ActionTypeDelete',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 9300,
    data: {}
  },
  {
    value: 93,
    name: 'ConferenceList',
    displayName: 'ActionTypeList',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 9400,
    data: {}
  },
  {
    value: 94,
    name: 'ConferenceCreate',
    displayName: 'ActionTypeCreate',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 9500,
    data: {}
  },
  {
    value: 95,
    name: 'ConferenceUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 9600,
    data: {}
  },
  {
    value: 96,
    name: 'ConferenceDelete',
    displayName: 'ActionTypeDelete',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 9700,
    data: {}
  },
  {
    value: 97,
    name: 'HomeWidgetVAMS',
    displayName: 'HomeWidgetVAMS',
    group: 'HomeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 9800,
    data: {}
  },
  {
    value: 98,
    name: 'HomeWidgetDoseCalculator',
    displayName: 'HomeWidgetDoseCalculator',
    group: 'HomeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 9900,
    data: {}
  },
  {
    value: 99,
    name: 'HomeWidgetPatientActivities',
    displayName: 'HomeWidgetPatientActivities',
    group: 'HomeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10000,
    data: {}
  },
  {
    value: 100,
    name: 'JourneyPage',
    displayName: 'JourneyPage',
    group: 'PageManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10100,
    data: {}
  },
  {
    value: 101,
    name: 'ContentPage',
    displayName: 'ContentPage',
    group: 'PageManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10200,
    data: {}
  },
  {
    value: 102,
    name: 'DeliveryFlowList',
    displayName: 'ActionTypeList',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10300,
    data: {}
  },
  {
    value: 103,
    name: 'DeliveryFlowAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 10400,
    data: {}
  },
  {
    value: 104,
    name: 'DeliveryFlowCreate',
    displayName: 'ActionTypeCreate',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 10500,
    data: {}
  },
  {
    value: 105,
    name: 'DeliveryFlowUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 10600,
    data: {}
  },
  {
    value: 106,
    name: 'DeliveryFlowDelete',
    displayName: 'ActionTypeDelete',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 10700,
    data: {}
  },
  {
    value: 107,
    name: 'DeliveryList',
    displayName: 'ActionTypeList',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10800,
    data: {}
  },
  {
    value: 108,
    name: 'DeliveryAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 10900,
    data: {}
  },
  {
    value: 109,
    name: 'DeliveryAll',
    displayName: 'ActionTypeAll',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 11000,
    data: {}
  },
  {
    value: 110,
    name: 'DeliveryUser',
    displayName: 'ActionTypeUser',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 11100,
    data: {}
  },
  {
    value: 111,
    name: 'DeliveryCreate',
    displayName: 'ActionTypeCreate',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 11200,
    data: {}
  },
  {
    value: 112,
    name: 'DeliveryUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 11300,
    data: {}
  },
  {
    value: 113,
    name: 'DeliveryReject',
    displayName: 'ActionTypeReject',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 11400,
    data: {}
  },
  {
    value: 114,
    name: 'ContentList',
    displayName: 'ActionTypeList',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 11500,
    data: {}
  },
  {
    value: 115,
    name: 'ContentAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 11600,
    data: {}
  },
  {
    value: 116,
    name: 'ContentCreate',
    displayName: 'ActionTypeCreate',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 11700,
    data: {}
  },
  {
    value: 117,
    name: 'ContentUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 11800,
    data: {}
  },
  {
    value: 118,
    name: 'ContentDelete',
    displayName: 'ActionTypeDelete',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 11900,
    data: {}
  },
  {
    value: 119,
    name: 'StorageList',
    displayName: 'ActionTypeList',
    group: 'StorageManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 12000,
    data: {}
  },
  {
    value: 120,
    name: 'StorageUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'StorageManagement',
    shortName: null,
    description: null,
    prompt: 'StorageList',
    order: 12100,
    data: {}
  },
  {
    value: 121,
    name: 'StorageDelete',
    displayName: 'ActionTypeDelete',
    group: 'StorageManagement',
    shortName: null,
    description: null,
    prompt: 'StorageList',
    order: 12200,
    data: {}
  },
  {
    value: 122,
    name: 'TreatmentList',
    displayName: 'ActionTypeList',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 12300,
    data: {}
  },
  {
    value: 123,
    name: 'TreatmentAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 12400,
    data: {}
  },
  {
    value: 124,
    name: 'TreatmentAll',
    displayName: 'ActionTypeAll',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 12500,
    data: {}
  },
  {
    value: 125,
    name: 'TreatmentCreate',
    displayName: 'ActionTypeCreate',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 12600,
    data: {}
  },
  {
    value: 126,
    name: 'TreatmentUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 12700,
    data: {}
  },
  {
    value: 127,
    name: 'TreatmentDelete',
    displayName: 'ActionTypeDelete',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 12800,
    data: {}
  },
  {
    value: 128,
    name: 'UserInviteList',
    displayName: 'ActionTypeList',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 12900,
    data: {}
  },
  {
    value: 129,
    name: 'UserInviteAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 13000,
    data: {}
  },
  {
    value: 130,
    name: 'UserInviteAll',
    displayName: 'ActionTypeAll',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 13100,
    data: {}
  },
  {
    value: 131,
    name: 'UserInviteCreate',
    displayName: 'ActionTypeCreate',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 13200,
    data: {}
  },
  {
    value: 132,
    name: 'UserInviteUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 13300,
    data: {}
  },
  {
    value: 133,
    name: 'UserInviteDelete',
    displayName: 'ActionTypeDelete',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 13400,
    data: {}
  },
  {
    value: 134,
    name: 'VisitList',
    displayName: 'ActionTypeList',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 13500,
    data: {}
  },
  {
    value: 135,
    name: 'VisitAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 13600,
    data: {}
  },
  {
    value: 136,
    name: 'VisitAll',
    displayName: 'ActionTypeAll',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 13700,
    data: {}
  },
  {
    value: 137,
    name: 'VisitCreate',
    displayName: 'ActionTypeCreate',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 13800,
    data: {}
  },
  {
    value: 138,
    name: 'VisitUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 13900,
    data: {}
  },
  {
    value: 139,
    name: 'VisitDelete',
    displayName: 'ActionTypeDelete',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 14000,
    data: {}
  },
  {
    value: 140,
    name: 'VitalList',
    displayName: 'ActionTypeList',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 14100,
    data: {}
  },
  {
    value: 141,
    name: 'VitalAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 14200,
    data: {}
  },
  {
    value: 142,
    name: 'VitalAll',
    displayName: 'ActionTypeAll',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 14300,
    data: {}
  },
  {
    value: 143,
    name: 'VitalCreate',
    displayName: 'ActionTypeCreate',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 14400,
    data: {}
  },
  {
    value: 144,
    name: 'VitalUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 14500,
    data: {}
  },
  {
    value: 145,
    name: 'VitalDelete',
    displayName: 'ActionTypeDelete',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 14600,
    data: {}
  },
  {
    value: 146,
    name: 'OwnerRelation',
    displayName: 'ActionTypeRelation',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 810,
    data: {
      userRole: 0,
      userRoleActionType: 7
    }
  },
  {
    value: 147,
    name: 'AdministratorRelation',
    displayName: 'ActionTypeRelation',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1410,
    data: {
      userRole: 1,
      userRoleActionType: 7
    }
  },
  {
    value: 148,
    name: 'ManagerRelation',
    displayName: 'ActionTypeRelation',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2010,
    data: {
      userRole: 2,
      userRoleActionType: 7
    }
  },
  {
    value: 149,
    name: 'EditorRelation',
    displayName: 'ActionTypeRelation',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2910,
    data: {
      userRole: 3,
      userRoleActionType: 7
    }
  },
  {
    value: 150,
    name: 'PSPRelation',
    displayName: 'ActionTypeRelation',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3210,
    data: {
      userRole: 4,
      userRoleActionType: 7
    }
  },
  {
    value: 151,
    name: 'DoctorRelation',
    displayName: 'ActionTypeRelation',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 3810,
    data: {
      userRole: 5,
      userRoleActionType: 7
    }
  },
  {
    value: 152,
    name: 'NurseRelation',
    displayName: 'ActionTypeRelation',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4410,
    data: {
      userRole: 6,
      userRoleActionType: 7
    }
  },
  {
    value: 153,
    name: 'PharmacistRelation',
    displayName: 'ActionTypeRelation',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5010,
    data: {
      userRole: 7,
      userRoleActionType: 7
    }
  },
  {
    value: 154,
    name: 'WarehouseRelation',
    displayName: 'ActionTypeRelation',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5610,
    data: {
      userRole: 8,
      userRoleActionType: 7
    }
  },
  {
    value: 155,
    name: 'IMSRelation',
    displayName: 'ActionTypeRelation',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6210,
    data: {
      userRole: 9,
      userRoleActionType: 7
    }
  },
  {
    value: 156,
    name: 'PatientParent',
    displayName: 'ActionTypeParent',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 6810,
    data: {
      userRole: 10,
      userRoleActionType: 8
    }
  },
  {
    value: 157,
    name: 'VAMS1TO5',
    displayName: 'VAMS1TO5',
    group: 'VAMSManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 14800,
    data: {}
  },
  {
    value: 158,
    name: 'VAMSTreatmentQuestion',
    displayName: 'VAMSTreatmentQuestion',
    group: 'VAMSManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 14900,
    data: {}
  },
  {
    value: 159,
    name: 'AdverseEventAllowSendReports',
    displayName: 'AdverseEventAllowSendReports',
    group: 'AdverseEventManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8810,
    data: {}
  },
  {
    value: 160,
    name: 'AdverseEventShowDisclaimer',
    displayName: 'AdverseEventShowDisclaimer',
    group: 'AdverseEventManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8820,
    data: {}
  },
  {
    value: 161,
    name: 'AskForHelpAllowSendReports',
    displayName: 'AskForHelpAllowSendReports',
    group: 'AskForHelpManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8810,
    data: {}
  },
  {
    value: 162,
    name: 'AskForHelpShowDisclaimer',
    displayName: 'AskForHelpShowDisclaimer',
    group: 'AskForHelpManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 8820,
    data: {}
  },
  {
    value: 163,
    name: 'ConferenceShareScreen',
    displayName: 'ActionTypeShareScreen',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 9410,
    data: {}
  },
  {
    value: 164,
    name: 'CalculatorEvrysdiNormal',
    displayName: 'CalculatorEvrysdiNormal',
    group: 'CalculatorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10010,
    data: {}
  },
  {
    value: 165,
    name: 'CalculatorEvrysdiShelfLife',
    displayName: 'CalculatorEvrysdiShelfLife',
    group: 'CalculatorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10020,
    data: {}
  },
  {
    value: 166,
    name: 'CalculatorEnspryngNormal',
    displayName: 'CalculatorEnspryngNormal',
    group: 'CalculatorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10030,
    data: {}
  },
  {
    value: 167,
    name: 'OwnerAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 1110,
    data: {
      userRole: 0,
      userRoleActionType: 9
    }
  },
  {
    value: 168,
    name: 'AdministratorAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1710,
    data: {
      userRole: 1,
      userRoleActionType: 9
    }
  },
  {
    value: 169,
    name: 'ManagerAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2310,
    data: {
      userRole: 2,
      userRoleActionType: 9
    }
  },
  {
    value: 170,
    name: 'EditorAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2610,
    data: {
      userRole: 3,
      userRoleActionType: 9
    }
  },
  {
    value: 171,
    name: 'PSPAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3510,
    data: {
      userRole: 4,
      userRoleActionType: 9
    }
  },
  {
    value: 172,
    name: 'DoctorAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 4110,
    data: {
      userRole: 5,
      userRoleActionType: 9
    }
  },
  {
    value: 173,
    name: 'NurseAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4710,
    data: {
      userRole: 6,
      userRoleActionType: 9
    }
  },
  {
    value: 174,
    name: 'PharmacistAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5310,
    data: {
      userRole: 7,
      userRoleActionType: 9
    }
  },
  {
    value: 175,
    name: 'WarehouseAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5910,
    data: {
      userRole: 8,
      userRoleActionType: 9
    }
  },
  {
    value: 176,
    name: 'IMSAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6510,
    data: {
      userRole: 9,
      userRoleActionType: 9
    }
  },
  {
    value: 177,
    name: 'PatientAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 7210,
    data: {
      userRole: 10,
      userRoleActionType: 9
    }
  },
  {
    value: 178,
    name: 'ConferenceAttachSurvey',
    displayName: 'ActionTypeAttachSurvey',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 9420,
    data: {}
  },
  {
    value: 179,
    name: 'RoleImportExport',
    displayName: 'ActionTypeImportExport',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 210,
    data: {}
  },
  {
    value: 180,
    name: 'DeliveryFlowImportExport',
    displayName: 'ActionTypeImportExport',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 10410,
    data: {}
  },
  {
    value: 181,
    name: 'TemplateImportExport',
    displayName: 'ActionTypeImportExport',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: 'TemplateList',
    order: 8410,
    data: {}
  },
  {
    value: 182,
    name: 'OwnerViewer',
    displayName: 'ActionTypeViewer',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 820,
    data: {
      userRole: 0,
      userRoleActionType: 10
    }
  },
  {
    value: 183,
    name: 'AdministratorViewer',
    displayName: 'ActionTypeViewer',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 1420,
    data: {
      userRole: 1,
      userRoleActionType: 10
    }
  },
  {
    value: 184,
    name: 'ManagerViewer',
    displayName: 'ActionTypeViewer',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 2020,
    data: {
      userRole: 2,
      userRoleActionType: 10
    }
  },
  {
    value: 185,
    name: 'EditorViewer',
    displayName: 'ActionTypeViewer',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 2920,
    data: {
      userRole: 3,
      userRoleActionType: 10
    }
  },
  {
    value: 186,
    name: 'PSPViewer',
    displayName: 'ActionTypeViewer',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 3220,
    data: {
      userRole: 4,
      userRoleActionType: 10
    }
  },
  {
    value: 187,
    name: 'DoctorViewer',
    displayName: 'ActionTypeViewer',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 3820,
    data: {
      userRole: 5,
      userRoleActionType: 10
    }
  },
  {
    value: 188,
    name: 'NurseViewer',
    displayName: 'ActionTypeViewer',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 4420,
    data: {
      userRole: 6,
      userRoleActionType: 10
    }
  },
  {
    value: 189,
    name: 'PharmacistViewer',
    displayName: 'ActionTypeViewer',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 5020,
    data: {
      userRole: 7,
      userRoleActionType: 10
    }
  },
  {
    value: 190,
    name: 'WarehouseViewer',
    displayName: 'ActionTypeViewer',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 5620,
    data: {
      userRole: 8,
      userRoleActionType: 10
    }
  },
  {
    value: 191,
    name: 'IMSViewer',
    displayName: 'ActionTypeViewer',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 6220,
    data: {
      userRole: 9,
      userRoleActionType: 10
    }
  },
  {
    value: 192,
    name: 'PatientViewer',
    displayName: 'ActionTypeViewer',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 6830,
    data: {
      userRole: 10,
      userRoleActionType: 10
    }
  },
  {
    value: 193,
    name: 'RelativeList',
    displayName: 'ActionTypeList',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 7220,
    data: {
      userRole: 11,
      userRoleActionType: 0
    }
  },
  {
    value: 194,
    name: 'RelativeAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7230,
    data: {
      userRole: 11,
      userRoleActionType: 1
    }
  },
  {
    value: 195,
    name: 'RelativeAll',
    displayName: 'ActionTypeAll',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7240,
    data: {
      userRole: 11,
      userRoleActionType: 2
    }
  },
  {
    value: 196,
    name: 'RelativeRelation',
    displayName: 'ActionTypeRelation',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7250,
    data: {
      userRole: 11,
      userRoleActionType: 7
    }
  },
  {
    value: 197,
    name: 'RelativeViewer',
    displayName: 'ActionTypeViewer',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7260,
    data: {
      userRole: 11,
      userRoleActionType: 10
    }
  },
  {
    value: 198,
    name: 'RelativeCreate',
    displayName: 'ActionTypeCreate',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7270,
    data: {
      userRole: 11,
      userRoleActionType: 4
    }
  },
  {
    value: 199,
    name: 'RelativeUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7280,
    data: {
      userRole: 11,
      userRoleActionType: 5
    }
  },
  {
    value: 200,
    name: 'RelativeDelete',
    displayName: 'ActionTypeDelete',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7290,
    data: {
      userRole: 11,
      userRoleActionType: 6
    }
  },
  {
    value: 201,
    name: 'RelativeAnonymize',
    displayName: 'ActionTypeAnonymize',
    group: 'RelativeManagement',
    shortName: null,
    description: null,
    prompt: 'RelativeList',
    order: 7295,
    data: {
      userRole: 11,
      userRoleActionType: 9
    }
  }
];

export default ActionTypes;