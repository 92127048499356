const NotificationTypes = [
  {
    value: 0,
    name: 'Delivery',
    displayName: 'NotificationTypeDelivery',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Treatment',
    displayName: 'NotificationTypeTreatment',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'Conference',
    displayName: 'NotificationTypeConference',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Content',
    displayName: 'NotificationTypeContent',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'WAMS',
    displayName: 'NotificationTypeWAMS',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  }
];

export default NotificationTypes;