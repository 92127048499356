import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { ActionType, ContentType, RoleFieldType, UserRelationType, UserRoleType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { conferenceCreate, contentListGlobal, userList } from '../../../services/api';
import { notifyError, notifySuccess } from '../../../utilies/notification';
import { Drawer, Element } from '../../globals';

export default function ConferenceCreate({ load, panelVisibility, setPanelVisibility }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userContents, setUserContents] = useState(null);
  const [ready, setReady] = useState(true);

  const userRoles = user.getUserRoles();

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: user?.c,
    currentUserId: user?.i,
    userRole: UserRoleType.Patient,
    includeFields: true,
    relationType: UserRelationType.Relation,
  });

  const [contentFilter, setContentFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    country: user.c,
    userCountry: user.c,
    includeCountries: true,
    includeUserRoles: true,
    includeDiseases: true,
    includeTherapeuticAreas: true,
    justParent: true,
    isAttachConference: true,
    type: ContentType.Survey,
  });

  // Hooks
  useEffect(() => {
    if (panelVisibility && userFilter.userRole !== undefined && userFilter.userRole !== null && userRoles.length > 0) {
      userFilterList();
    }
  }, [panelVisibility]);

  useEffect(() => {
    loadContent();
  }, [contentFilter]);

  // Functions
  const userFilterList = async () => {
    formRef.resetFields();
    setSelectedUser(null);

    await userList(userFilter, (status, res) => {
      if (status) {
        setUsers(res.data);
      }
    });
  };

  const loadContent = async () => {
    if (!selectedUser) return;

    contentListGlobal(contentFilter, (status, res) => {
      if (status) {
        setUserContents(res.data);
      }
    });
  };

  // Events
  const onFinish = async (model) => {
    if (model.dates.some((x) => x.date < moment())) {
      notifyError(t('InvalidConferenceDates'));
      return;
    }

    setReady(false);
    let dates = model.dates.map((x) => moment(x.date).add(-user.t, 'minutes').format('YYYY-MM-DDTHH:mm'));
    model = { ...model, dates, country: userFilter.country };
    await conferenceCreate(model, (status, res) => {
      if (status) {
        load();
        notifySuccess(t('CreateCompleteSuccess'));
        setPanelVisibility(false);
      }

      setReady(true);
    });
  };

  const onUserChange = ({ value }) => {
    if (!value) {
      setSelectedUser(null);
      return;
    }

    let selectedUser = users.find((x) => x.id === value);

    if (selectedUser.fields) {
      let diseaseTypes = selectedUser.fields.find((x) => x.fieldType === RoleFieldType.DiseaseTypes);
      if (diseaseTypes) {
        setContentFilter((x) => ({ ...x, disease: diseaseTypes.value }));
      }
    } else {
      setContentFilter((x) => ({ ...x, disease: null }));
    }

    setSelectedUser({
      ...selectedUser,
      isVerified: selectedUser.isVerified,
      timeOffset: user.t - selectedUser.timeOffset,
    });
  };

  // Render
  return (
    <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)} width={500}>
      {(selectedUser?.isVerified === false || selectedUser?.timeOffset > 0) && (
        <Information>
          <ImportantInformation>
            <FaInfoCircle /> {t('ImportantInformation')}
          </ImportantInformation>
          {!selectedUser.isVerified && <LoginStatus>{t('UserNotLoginSystem')}</LoginStatus>}
          {selectedUser.timeOffset > 0 && (
            <TimeOffsetStatus>
              {format(
                t('ConferenceTimeDifference'),
                <strong>
                  {selectedUser.timeOffset} {t('minutes')} ({parseFloat(selectedUser.timeOffset) / 60} {t('hours')})
                </strong>
              )}
            </TimeOffsetStatus>
          )}
        </Information>
      )}

      <Element
        key="conference-form"
        columnSize={24}
        ready={ready}
        formRef={formRef}
        onFinish={onFinish}
        inputs={[
          {
            type: 'select',
            name: 'targetUserId',
            label: t('User'),
            placeholder: t('SelectUser'),
            data: users.map((x) => ({ value: x.id, text: x.fullName })),
            rules: [{ required: true, message: t('UserEmpty') }],
            onChange: onUserChange,
            allowClear: false,
          },
          userContents?.length > 0 &&
            user.checkAction(ActionType.ConferenceAttachSurvey) && {
              type: 'select',
              name: 'contentId',
              label: t('Survey'),
              placeholder: t('SelectSurvey'),
              data: userContents.map((x) => ({ value: x.id, text: x.title })),
              allowClear: true,
            },
          {
            name: 'dates',
            add: t('AddDate'),
            remove: true,
            list: [
              {
                type: 'date',
                name: 'date',
                label: 'Date',
                showTime: true,
                placeholder: t('SelectDate'),
                rules: [{ required: true, message: 'DateEmpty' }],
                disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
              },
            ],
            templates: ['multiple', 'empty-bottom'],
          },
        ]}
        submit={t('Confirm')}
      />
    </Drawer>
  );
}

const Information = styled.div`
  margin-bottom: 42px;
`;

const ImportantInformation = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${(x) => x.theme.colors.darkTurquoise};
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
  }
`;

const LoginStatus = styled.div``;

const TimeOffsetStatus = styled.div``;
